.App {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 1rem;
    -ms-user-select: none; 
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.kokomi {
    height: 100%;
    width: 100%;
}

.numbers {
    font-size: 2rem;
    font-weight: 800;
}

@media (max-width: 499px){
    .numbers {
        font-size: 0.8rem;
    }
}

@media (min-width: 500px) and (max-width: 1004px) {
    .numbers {
        font-size: 1.5rem;
    }
}

@media (min-width: 1005px) and (max-width: 1247px) {
    .numbers {
        font-size: 1.8rem;
    }
}

@media (min-width: 1248px) {
    .numbers {
        font-size: 2rem;
    }
}